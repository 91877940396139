
















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import playerjs from 'player.js'
import Analytics from '@/utils/analytics'
import { postOnPlay, postOnPause, postOnListened, postOnTimeUpdate, postOnEnded } from '@/utils/events'
import { Getter } from 'vuex-class'
import get from 'lodash.get'

@Component
export default class Audio extends Vue {
  @Prop(Boolean) autoplay!: boolean

  @Getter srcAdded!: boolean

  @Watch('autoplay')
  autoplayAudio() {
    if (this.srcAdded && this.autoplay) {
      const audio: any = this.$refs.audio
      audio.play()
    }
  }

  mounted() {
    const adapter = playerjs.HTML5Adapter(this.$refs.audio)
    this.$store.dispatch('setAudio', this.$refs.audio)
    this.$store.dispatch('setReceiver', adapter)
    this.$nextTick(() => {
      this.$emit('on-load')
    })
  }

  onPlay() {
    this.$store.dispatch('setPlaying')
    postOnPlay(this.episodeId, this.currentTime, this.duration)
  }
  onPause() {
    this.$store.dispatch('setPaused')
    Analytics.submitOnInterval()
    postOnPause(this.episodeId, this.currentTime, this.duration)
  }
  onLoadedmetadata() {
    const audio: any = this.$refs.audio
    this.$store.dispatch('setDuration', audio.duration)
    this.$store.dispatch('setCurrentTime', this.startTime > 0 ? this.startTime : 0)
    this.$store.dispatch('setPlaybackRate', audio.defaultPlaybackRate)
  }
  onTimeUpdate() {
    const audio: any = this.$refs.audio
    const currentTime = audio.currentTime
    if (this.stopTime && this.stopTime !== -1 && currentTime >= this.stopTime && this.isBookmarkClip) {
      audio.pause()
      this.$store.dispatch('setStopTime', -1)
      this.$store.dispatch('setEventData', {
        key: 'isBookmarkClip',
        value: false,
      })
      this.$emit('handleAudioClipPause')
    }
    // temporary change
    this.$store.dispatch('setCurrentTime', currentTime)
    postOnTimeUpdate(this.episodeId, currentTime, this.duration)
  }
  onRateChange() {
    const audio: any = this.$refs.audio
    this.$store.dispatch('setPlaybackRate', audio.playbackRate)
  }
  onLoadedData(e) {
    this.receiver.ready()
    // this.$emit('on-load')
  }
  onError() {
    this.$emit('error')
  }
  onSeeked() {
    postOnListened(this.episodeId, this.currentTime, this.duration)
  }
  onEnded() {
    postOnEnded(this.episodeId, this.currentTime, this.duration)
  }

  get receiver() {
    return this.$store.getters.receiver
  }
  get startTime() {
    return this.$store.getters.startTime
  }
  get stopTime() {
    return this.$store.getters.stopTime
  }
  get isBookmarkClip() {
    return this.$store.getters.isBookmarkClip
  }
  get episodeId() {
    return get(this.$store.getters, 'episode.uid', '')
  }
  get currentTime() {
    return this.$store.getters.currentTime
  }
  get duration() {
    return this.$store.getters.duration
  }
}
