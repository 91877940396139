












































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Poll extends Vue {
  @Prop(Object) tag: any
  @Prop(Array) tagPositions!: any
  @Prop(Number) currentTime!: number

  fontClasses = ['rubik', 'unica-one', 'permanent-marker', 'marck-script', 'dosis']
  showPoll = false
  choiceSelected: any = null
  pollSubmitted = false

  get choices() {
    const choices = this.tag.choices
    return [choices.choice1, choices.choice2, choices.choice3, choices.choice4]
  }
  get tagExperience() {
    return this.$store.getters.experiencesById(this.tag.expId)
  }
  get pollStatus() {
    return this.tagExperience && this.tagExperience.interaction && this.tagExperience.interaction.pollStatus
  }
  get userChoice() {
    return this.pollStatus && this.pollStatus.choice && parseInt(this.pollStatus.choice.slice(-1))
  }
  get results() {
    const results = this.pollStatus && this.pollStatus.result
    return results ? [results.choice1, results.choice2, results.choice3, results.choice4] : []
  }
  get resultsPercentage() {
    return this.results.map((result) => (result * 100) / this.totalVotes)
  }
  get totalVotes() {
    return this.results.reduce((a, b) => a + b, 0)
  }

  async submitPoll(option) {
    this.pollSubmitted = true
    await this.$emit('submitPoll', option + 1)
    this.pollSubmitted = false
  }
}
