function selectTagPosition(tagPositions, currentTime) {
  let selectedTag = null
  if (tagPositions && tagPositions.length > 0) {
    for (const tagPosition of tagPositions) {
      let timeinsec = tagPosition.offsetMillis / 1000
      if (currentTime >= timeinsec && currentTime <= timeinsec + 30) {
        selectedTag = tagPosition
      }
    }
  }
  return selectedTag
}

function getNextTagPosition(tagPositions, currentTime) {
  return tagPositions.find((element) => {
    return element.offsetMillis / 1000 > currentTime
  })
}

function getPrevTagPosition(tagPositions, currentTime) {
  let reversedTagPositions = tagPositions.slice().reverse()
  let currentTagPosition = reversedTagPositions.find((element) => {
    return currentTime >= element.offsetMillis / 1000 && currentTime <= element.offsetMillis / 1000 + 30
  })
  if (currentTagPosition === undefined) {
    return reversedTagPositions.find((element) => {
      return element.offsetMillis / 1000 < currentTime
    })
  } else {
    return reversedTagPositions.find((element) => {
      return element.offsetMillis < currentTagPosition.offsetMillis
    })
  }
}

export { selectTagPosition, getNextTagPosition, getPrevTagPosition }
