import { ADORI_WEBPLAYER_VERSION } from '@/constants'
import { postOnListened } from '@/utils/events'
import store from '@/store'
import route from '@/router'
import AdoriService from '@/services/adori'
import ERRORS from '@/utils/errorMessages'
import get from 'lodash.get'

let allEvents: any = []
let previousOffset = 0

const episodeUid = () => {
  return get(store.getters, 'episode.uid', '')
}
const currentTime = () => {
  return store.getters.currentTime * 1000 || 0
}
const duration = () => {
  return store.getters.duration
}
const isPreview = () => {
  return route.currentRoute.query.mode === 'preview'
}
const deviceInfo = () => {
  return {
    ancestorOrigins: Object.values(window.location.ancestorOrigins || {}),
    referer: route.currentRoute.query.referrer || document.referrer || location.href.split('?')[0],
    sdkVersion: ADORI_WEBPLAYER_VERSION,
  }
}
const getTagType = (tag) => {
  switch (tag.actions) {
    case 'click':
      return tag.url ? 'CLICK' : 'LIKE'
    case 'choose':
      return 'POLL'
    case 'navigate':
      return 'NAVIGATE'
    case 'call':
      return 'CALL'
    default:
      return 'CLICK'
  }
}

const submitAnalytics = async () => {
  const payload = {
    webDeviceInfo: deviceInfo(),
    events: allEvents,
  }
  try {
    await AdoriService.webplayerAnalytics(payload)
    allEvents = []
    previousOffset = currentTime()
  } catch (error) {
    console.log(ERRORS.analytics)
  }
}

const submitOnInterval = () => {
  postEpisodeOut()
  if (allEvents.length !== 0 && !isPreview()) submitAnalytics()
  postOnListened(episodeUid(), currentTime() && currentTime() / 1000, duration())
}

const startAnalyticsPoll = async () => {
  if (!isPreview()) {
    await postEpisodeIn()
    await submitAnalytics()
    setInterval(submitOnInterval, 30000)
  }
}

// =================================================
// Events
// =================================================

const postEpisodeIn = () => {
  const event = {
    name: 'EpisodeIn',
    params: {
      absoluteTime: Date.now(),
      episodeUid: episodeUid(),
      startOffsetTime: currentTime(),
    },
  }
  allEvents.push(event)
}

const postEpisodeOut = () => {
  const event = {
    name: 'EpisodeOut',
    params: {
      absoluteTime: Date.now(),
      episodeUid: episodeUid(),
      endOffsetTime: currentTime(),
      startOffsetTime: previousOffset,
    },
  }
  if (event.params.startOffsetTime !== event.params.endOffsetTime) {
    allEvents.push(event)
  }
}

const postTagServed = (currentTag) => {
  const event = {
    name: 'TagServed',
    params: {
      absoluteTime: Date.now(),
      expId: currentTag.id,
    },
  }
  allEvents.push(event)
}

const postTagInteracted = (currentTag) => {
  const event = {
    name: 'TagInteracted',
    params: {
      absoluteTime: Date.now(),
      expId: currentTag.expId,
      type: getTagType(currentTag),
    },
  }
  allEvents.push(event)
}

export default {
  postEpisodeIn,
  postTagServed,
  postEpisodeOut,
  postTagInteracted,
  startAnalyticsPoll,
  submitOnInterval,
}
