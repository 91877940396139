import store from '@/store'

// ================================================================
// Incoming Events
// ================================================================
window.addEventListener('message', (e) => {
  const data = e.data
  if (data.context && data.context === 'adori-web-player' && data.eventName) {
    switch (data.eventName) {
      case 'play':
        playEpisode()
        break
      case 'pause':
        pauseEpisode()
        break
      case 'stop':
        stopEpisode()
        break
      case 'seekTo':
        seekTo(data.info)
        break
      case 'setupSDK':
        handleWebSdkSetup(data.info)
        break
      case 'playEpisode':
        handlePlayEpisode(data.info)
        break
      case 'setUserId':
        handleSetUserId(data.info)
        break
      case 'setApiKey':
        handleApiKey(data.info)
        break
      case 'closePlayer':
        handleClosePlayer(data.info)
        break
    }
  }
})

const playEpisode = () => {
  store.dispatch('setPlaying')
}
const pauseEpisode = () => {
  store.commit('setPaused')
}
const stopEpisode = () => {
  store.commit('setPaused')
  store.commit('seekAudio', 0)
}
const seekTo = (info) => {
  store.dispatch('setStartTime', info.seekTo)
  store.commit('seekAudio', info.seekTo)
}

const handleClosePlayer = (info) => {
  postMessageToParent({
    context: 'adori-web-player',
    eventName: 'onFinished',
    info: {
      episodeUid: store.getters.episode.uid,
      time: store.getters.currentTime,
    },
  })
}

const handleWebSdkSetup = (info) => {
  store.dispatch('setupWebSDK', info)
}

const handleSetUserId = (info) => {
  store.dispatch('setUserId', info.userId)
}

const handleApiKey = (info) => {
  store.dispatch('setApiKey', info.apiKey)
}

const handlePlayEpisode = (info) => {
  store.dispatch('setPlayEpisodeData', info)
  if (info.startTime) store.dispatch('setStartTime', info.startTime)
  if (info.stopTime) store.dispatch('setStopTime', info.stopTime)
}

// ================================================================
// Outgoing Events
// ================================================================

const postMessageToParent = (data) => {
  window.parent.postMessage(data, '*')
}

const postClipped = (episodeUid, startTime, endTime) => {
  postMessageToParent({
    context: 'adori-web-player',
    eventName: 'onClip',
    info: {
      episodeUid: episodeUid,
      startTime: startTime,
      endTime: endTime,
    },
  })
}

const postBookmarked = (episodeUid, startTime) => {
  postMessageToParent({
    context: 'adori-web-player',
    eventName: 'onBookmark',
    info: {
      episodeUid: episodeUid,
      startTime: startTime,
    },
  })
}

const postPlaylisted = (episodeUid, playlisted) => {
  postMessageToParent({
    context: 'adori-web-player',
    eventName: 'onPlaylist',
    info: {
      episodeUid: episodeUid,
      playlisted: playlisted,
    },
  })
}

const postLiked = (episodeUid, liked) => {
  postMessageToParent({
    context: 'adori-web-player',
    eventName: 'onLike',
    info: {
      episodeUid: episodeUid,
      liked: liked,
    },
  })
}

const postCurrentTag = (tag) => {
  postMessageToParent({
    context: 'adori-web-player',
    eventName: 'currentTag',
    info: {
      tag: tag,
    },
  })
}

export const postOnPlay = (episodeUid, currentTime, duration) => {
  postMessageToParent({
    context: 'adori-web-player',
    eventName: 'onPlay',
    info: {
      episodeUid,
      currentTime,
      duration,
    },
  })
}

export const postOnPause = (episodeUid, currentTime, duration) => {
  postMessageToParent({
    context: 'adori-web-player',
    eventName: 'onPause',
    info: {
      episodeUid,
      currentTime,
      duration,
    },
  })
}

export const postOnTimeUpdate = (episodeUid, currentTime, duration) => {
  postMessageToParent({
    context: 'adori-web-player',
    eventName: 'onTimeUpdate',
    info: {
      episodeUid,
      currentTime,
      duration,
    },
  })
}

export const postOnListened = (episodeUid, currentTime, duration) => {
  postMessageToParent({
    context: 'adori-web-player',
    eventName: 'onListened',
    info: {
      episodeUid: episodeUid,
      time: currentTime,
      duration,
    },
  })
}

export const postOnEnded = (episodeUid, currentTime, duration) => {
  postMessageToParent({
    context: 'adori-web-player',
    eventName: 'onEnded',
    info: {
      episodeUid: episodeUid,
      time: currentTime,
      duration,
    },
  })
}

export const postShowLogin = (episodeUid) => {
  postMessageToParent({
    context: 'adori-web-player',
    eventName: 'showLogin',
    info: {
      episodeUid: episodeUid,
    },
  })
}

export const postOnShare = (episodeUid) => {
  postMessageToParent({
    context: 'adori-web-player',
    eventName: 'onShare',
    info: {
      episodeUid: episodeUid,
    },
  })
}

export const postOpenEpisode = (episodeUid) => {
  postMessageToParent({
    context: 'adori-web-player',
    eventName: 'openEpisode',
    info: {
      episodeUid: episodeUid,
    },
  })
}

export const postOnResize = (isMiniPlayer) => {
  postMessageToParent({
    context: 'adori-web-player',
    eventName: 'playerResize',
    info: {
      isMiniPlayer: isMiniPlayer,
    },
  })
}

export const postOnError = (error) => {
  postMessageToParent({
    context: 'adori-web-player',
    eventName: 'playerError',
    info: {
      error,
    },
  })
}

export default {
  postBookmarked,
  postPlaylisted,
  postLiked,
  postClipped,
  postOpenEpisode,
  postCurrentTag,
  postOnPlay,
  postOnPause,
  postOnTimeUpdate,
  postOnListened,
  postOnEnded,
  postOnShare,
  postOnResize,
  postOnError,
}
